<template>
  <div class="demand-create-desktop">
    <div
        v-if="initLoading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>
    <v-card
        v-else
        :disabled="demand.loading"
        :loading="demand.loading"
        class="demand-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-pencil"
            title="ویرایش درخواست"/>
      </v-card-title>

      <v-card-text>
        <v-row class="my-4">
          <v-col :cols="3">
            <v-text-field
                dense
                v-model="demand.name"
                hide-details
                outlined
                label="نام مراجعه کننده"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field
                dense
                v-model="demand.phone"
                hide-details
                outlined
                label="شماره تماس"
            />
          </v-col>
          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع معامله را انتخاب کنید"
                :items="transactionTypes"
                item-value="id"
                item-text="name"
                v-model="demand.transactionType"
            />
          </v-col>
          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع ملک را انتخاب کنید"
                :items="demandTypes"
                item-value="id"
                item-text="name"
                v-model="demand.propertyType"
            />
          </v-col>
        </v-row>

        <v-row>

          <v-col :cols="6">
            <v-row dense>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label
                    for="minBaseArea"
                    class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محدوده متراژ ملک
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.baseArea.min"
                      hide-details
                      outlined
                      id="minBaseArea"
                  />
                  <label for="maxBaseArea" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.baseArea.max"
                      hide-details
                      outlined
                      id="maxBaseArea"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minFloor" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محدوده طبقه ملک
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.floor.min"
                      hide-details
                      outlined
                      id="minFloor"
                  />
                  <label for="maxFloor" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.floor.max"
                      hide-details
                      outlined
                      id="maxFloor"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minBuildingAge" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محوده سن بنا
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.buildingAge.min"
                      hide-details
                      outlined
                      id="minBuildingAge"
                  />
                  <label for="maxBuildingAge" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.buildingAge.max"
                      hide-details
                      outlined
                      id="maxBuildingAge"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minLandArea" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محوده مساحت زمین
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.landArea.min"
                      hide-details
                      outlined
                      id="minLandArea"
                  />
                  <label for="maxLandArea" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.landArea.max"
                      hide-details
                      outlined
                      id="minLandArea"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols="6">
            <v-row dense>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minTotalPrice" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  بودجه مورد نظر خرید
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      style="max-width: 150px"
                      dense
                      v-model="demand.totalPrice.min"
                      hide-details
                      outlined
                      id="minTotalPrice"
                  />
                  <label for="maxTotalPrice" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      dense
                      style="max-width: 150px"
                      v-model="demand.totalPrice.max"
                      hide-details
                      outlined
                      id="maxTotalPrice"
                  />
                </div>
              </v-col>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minPricePerMeter" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  بودجه مورد نظر اجاره
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      style="max-width: 150px"
                      dense
                      v-model="demand.pricePerMeter.min"
                      hide-details
                      outlined
                      id="minPricePerMeter"
                  />
                  <label for="maxPricePerMeter" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      dense
                      style="max-width: 150px"
                      v-model="demand.pricePerMeter.max"
                      hide-details
                      outlined
                      id="maxPricePerMeter"
                  />
                </div>
              </v-col>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minViewDay" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  روز های بازدید
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-select
                      :items="daysOfWeek"
                      item-value="id"
                      item-text="name"
                      style="max-width: 150px"
                      dense
                      v-model="demand.viewDay.min"
                      hide-details
                      outlined
                      id="minViewDay"
                  />
                  <label for="maxViewDay" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-select
                      :items="daysOfWeek"
                      item-value="id"
                      item-text="name"
                      dense
                      style="max-width: 150px"
                      v-model="demand.viewDay.max"
                      hide-details
                      outlined
                      id="maxViewDay"
                  />
                </div>
              </v-col>
              <v-col :cols="12">
                <v-row dense>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.days"
                        hide-details
                        outlined
                        label="مهلت زمان (روز)"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        dense
                        type="number"
                        v-model="demand.liquidityPercentage"
                        hide-details
                        outlined
                        label="درصد نقدینگی(%)"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.personCount"
                        hide-details
                        outlined
                        label="تعداد نفرات"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.NumberOfSleeps"
                        hide-details
                        outlined
                        label="تعداد خواب"
                    />
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-col>

        </v-row>


        <v-row class="my-4">
          <v-col
              v-for="(property ,index) in properties"
              :key="index"
              :cols="2">
            <v-checkbox
                v-model="demand.properties"
                hide-details
                dense
                :value="property.id"
                :label="property.name"
            />
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <v-combobox
                v-model="demand.locations"
                class="mt-2"
                label="مناطق درخواستی"
                item-value="id"
                item-text="name"
                :items="locations"
                multiple
                outlined
                chips
                deletable-chips
                small-chips
            />

            <v-textarea
                v-model="demand.description"
                outlined
                height="150"
                label="توضیحات"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="mt-4" :cols="12">
            <v-btn
                :loading="demand.loading"
                color="primary" large @click="submitDemand">
              ویرایش درخواست
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle"
import {find, update} from "@Newfiling/module-demand/src/api";
import {AppConfig} from "@Newfiling/Services";
import Vue from "vue";
import {addCommas, removeCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopFileCreate',

  components: {
    PageTitle,
  },

  data() {
    return {
      initLoading: true,
      transactionTypes: [],
      demandTypes: [],
      daysOfWeek: [
        {
          id: 0,
          name: "شنبه"
        },
        {
          id: 1,
          name: "یکشنبه"
        },
        {
          id: 2,
          name: "دوشنبه"
        },
        {
          id: 3,
          name: "سه شنبه"
        },
        {
          id: 4,
          name: "چهار شنبه"
        },
        {
          id: 5,
          name: "پنج شنبه"
        },
        {
          id: 6,
          name: "جمعه"
        },
      ],
      properties: [],
      locations: [],
      demand: {
        loading: false,
        name: "",
        phone: "",
        transactionType: "",
        propertyType: "",
        baseArea: {
          min: "",
          max: "",
        },
        floor: {
          min: "",
          max: "",
        },
        buildingAge: {
          min: "",
          max: "",
        },
        landArea: {
          min: "",
          max: "",
        },
        totalPrice: {
          min: '',
          max: '',
        },
        pricePerMeter: {
          min: '',
          max: '',
        },
        viewDay: {
          min: '',
          max: '',
        },
        days: '',
        liquidityPercentage: '',
        personCount: '',
        personal: false,
        realEstate: false,
        properties: [],
        locations: [],
        description: '',
        NumberOfSleeps: "",
      }
    }
  },

  mounted() {
    this.$nextTick(async () => {
      this.initLoading = true;
      const appConfigs = (await AppConfig) || {};
      this.transactionTypes = appConfigs?.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name: item.Id === 1 ? 'خرید' :  item.Typeoftransaction ,
        }
      })
      this.demandTypes = appConfigs?.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.properties = appConfigs.DemandFeaturesTB.map(item => {
        return {
          id: item.Id,
          name: item.Persianname,
        }
      })
      this.locations = appConfigs?.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      await this.fetchDemand(this.$route.params.id);

      this.initLoading = false;
    })
  },

  methods: {

    async fetchDemand(id) {
      try {
        const res = (await find(id))?.data?.message || {};
        this.demand = {
          ...this.demand,
          id: res.id,
          NumberOfSleeps: res.numberOfSleeps,
          name: res.clientName,
          phone: res.mobile,
          transactionType: res.typeofdemand,
          propertyType: res.propertyType,
          baseArea: {
            min: res.minmummetraj,
            max: res.maximummetraj,
          },
          floor: {
            min: res.floornumber1,
            max: res.floornumber2,
          },
          buildingAge: {
            min: res.minimumbuildingage,
            max: res.maximumbuildingage,
          },
          landArea: {
            min: res.landarea1,
            max: res.landarea2,
          },
          totalPrice: {
            min: addCommas(res.minimumSalesBudget || 0),
            max: addCommas(res.maximumSalesBudget || 0),
          },
          pricePerMeter: {
            min: addCommas(res.minimumRentBudget || 0),
            max: addCommas(res.maximumRentBudget || 0),
          },
          viewDay: {
            min: res.startdayTheDayOfTheVisit,
            max: res.enddayTheDayOfTheVisit,
          },
          days: res.deadline,
          liquidityPercentage: res.liquidityPercentage,
          personCount: res.numberofpeople,
          personal: false,
          realEstate: false,
          properties: this.properties.filter(i => {
            const selectedProperties = res.attributes.split('-').map(j => parseInt(j));
            return res.attributes?.split('-')?.map(j => parseInt(j)).includes(i.id)
          }).map(i => i.id),
          locations: this.locations.filter(i => [res.locationsid1, res.locationsid2, res.locationsid3].includes(i.id)),
          description: res.description,
        }
      } catch (e) {
        console.log(e)
      }
    },

    async submitDemand() {
      this.demand.loading = true;
      try {

        const normalize = (value) => {
          if (String(value).trim() === '') {
            return 0;
          }
          return Number(value);
        }
        const data = {
          type: 3,
          id: this.demand.id,
          "Requesttitle": "test",
          clientName: this.demand.name,
          NumberOfSleeps: this.demand.NumberOfSleeps,
          Mobile: this.demand.phone,
          Typeofdemand: normalize(this.demand.transactionType),
          PropertyType: normalize(this.demand.propertyType),
          minmummetraj: normalize(this.demand.baseArea.min),
          maximummetraj: normalize(this.demand.baseArea.max),
          floorNumber: normalize(this.demand.floor.min),
          floorNumber2: normalize(this.demand.floor.max),
          minimumbuildingage: normalize(this.demand.buildingAge.min),
          maximumbuildingage: normalize(this.demand.buildingAge.max),
          landarea1: Number(normalize(this.demand.landArea.min)),
          landarea2: Number(normalize(this.demand.landArea.max)),
          minimumSalesBudget: normalize(removeCommas(this.demand.totalPrice.min)),
          maximumSalesBudget: normalize(removeCommas(this.demand.totalPrice.max)),
          minimumRentBudget: normalize(removeCommas(this.demand.pricePerMeter.min)),
          maximumRentBudget: normalize(removeCommas(this.demand.pricePerMeter.max)),
          startdayTheDayOfTheVisit: this.demand.viewDay.min,
          enddayTheDayOfTheVisit: this.demand.viewDay.max,
          deadline: normalize(this.demand.days),
          liquidityPercentage: this.demand.liquidityPercentage,
          NumberOfPeople: normalize(this.demand.personCount),
          zonkenPersonal: true,
          zonkenRealEstat: true,
          Attributes: this.demand.properties.join('-'),
          description: this.demand.description,
          neighborhoods: this.demand.locations.map(item => item.id).join('-'),
        };
        await update(this.demand.id, data);
        this.$toast.success('درخواست با موفقیت ویرایش شد.');
        await this.$router.back();
      } catch (e) {
        console.log(e)
      }
      this.demand.loading = false;
    }
  },

  watch: {
    'demand.totalPrice.min'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.totalPrice.min = result);
    },
    'demand.totalPrice.max'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.totalPrice.max = result);
    },
    'demand.pricePerMeter.min'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.pricePerMeter.min = result);
    },
    'demand.pricePerMeter.max'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.pricePerMeter.max = result);
    },
  }

}
</script>

<style scoped>
</style>
